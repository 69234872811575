<!-- 企业资料 -->
<template>
  <div class="firm_data">
    <div class="right_main">

      <!-- 内容 -->
      <div class="list">
        <div class="list_left">
          <div class="left_l">
            <img :src="licenseImage" alt="" style="width:88px;height:88px;border-radius: 50%;" />
            <!-- <img src="../../../../assets/images/admin_logo.png" alt="" style="width:88px;height:88px;border-radius: 50%;" /> -->
          </div>
          <div class="left_r">
            <div class="left_r_item">
              公司名：<span>{{ name }}</span>
            </div>
            <div class="left_r_item">
              公司地址：<span>{{ address }}</span>
            </div>
            <div class="left_r_items" v-if="this.status == 1">
              <div class="left_status" style="background:rgb(255, 172, 50,0.10),color:rgb(255, 131, 0);">
                资料审核中
              </div>
              <div class="left_status_text">
                我们将在24小时内完成资料审核，请您耐心等待。
              </div>
            </div>
            <div class="left_r_items" v-else-if="this.status == 2">
              <div class="left_status" style="background:rgba(224,40,40,0.10),color:#E02828;">
                未通过审核
              </div>
              <div class="left_status_text" v-if="this.reviews" style="color:#E02828;">
                {{ reviews1 }}
              </div>
              <div class="left_status_text" v-else style="color:#E02828;">
                您的社会信用代码与营业执照不符，请重新上传资料
              </div>
            </div>
            <div class="left_r_items" v-else-if="this.status == 3">
              <div class="left_status" style="background:rgb(232, 255, 234),color:#00B42A;">
                已通过审核
              </div>
            </div>
          </div>
        </div>
        <div class="list_right" style="cursor:pointer;" @click="company_detail(enterpriseId)">
          修改资料
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enterpriseId: '',
      licenseImage: require('@/assets/images/B_user/admin_logo.png'),
      name: '',
      address: '',
      status: 0,
      reviews: '',
      reviews1: ''

    }
  },
  methods: {

    get_data() {
      // 获取企业信息
      this.app("enterpriseOperators", "getEnterprise", {
        enterpriseId: this.enterpriseId,
      }).then(res => {
        console.log(res)
        var result = res.result;

        if (result.creditCode && result.licenseImage) {

          this.name = result.name
          this.address = result.address
          if (result.logo) {
            this.licenseImage = this.img_url(result.logo)
          }

          this.status = result.status
          this.reviews = result.reviews
          this.reviews1 = result.reviews[0]


        } else {
          this.$Modal.warning({
            title: '企业信息未完善，请先完善信息。',
          });
        }

      })
    },


    // 企业详情
    company_detail(id) {
      console.log(id)
      this.$router.push({ name: 'firm_data_detail', query: { id: id, change_company: 1 } })
    },

  },
  created() {
    // 企业id
    var enterpriseId = localStorage.getItem("firm_enterpriseId");
    this.enterpriseId = enterpriseId

    if (enterpriseId == null || enterpriseId == undefined || enterpriseId == "") {

      this.$Modal.warning({
        title: '企业信息未完善，请先完善信息。',
      });
    }

    this.get_data()
  }
}
</script>

<style lang="less" scoped>
.right_main {
  // min-height: 900px;
  background: #f5f7fa;
  padding-left: 24px;
  padding-top: 26px;
  padding-right: 24px;
}
.list {
  /* display:none; */
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 14px;
  padding-bottom: 25px;
  padding-top: 36px;
  opacity: 1;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.list_left {
  display: flex;
  align-items: center;
}
.left_r {
  margin-left: 42px;
}
.left_r_item {
  color: #606266;
  font-size: 14px;
  margin-bottom: 12px;
  padding-left: 9px;
}
.left_r_items {
  display: flex;
  align-items: center;
}

.left_status {
  padding: 4px 9px;
  border-radius: 2px;
  margin-right: 20px;
  background: #e8ffea;
  color: #00b42a;
  font-size: 12px;
}
.left_status_text {
  font-size: 13px;
  color: #606266;
}

.list_right {
  text-align: top;
  font-size: 14px;
  color: #165dff;
}
</style>
